import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
// import { config } from "../Constants";
import deployTokenSlice from "./deployToken/deployTokenSlice";
import authSlice from "./auth/authSlice";

// const url = config.url.API_URL;

export const store = configureStore({
  reducer: {
    auth: authSlice,
    deployToken: deployTokenSlice,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
  // devTools: url !== "https://dev.silvermine.com",
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
