import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ServerURL, Token } from "types";

const initialState = {
  account: "",
  server: "",
  serverURL: {} as ServerURL,
  token: {} as Token,
  tokenContentList: [] as Token[],
  currentPage: 1,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    connectAccount: (state, action: PayloadAction<string[]>) => {
      return {
        ...state,
        account: action.payload[0],
        server: action.payload[1],
      };
    },
    connectServer: (state, action: PayloadAction<ServerURL>) => {
      return { ...state, serverURL: { ...action.payload } };
    },
    disconnect: (state) => {
      return {
        ...state,
        account: "",
        server: "",
        serverURL: {} as ServerURL,
        token: {} as Token,
        tokenContentList: [] as Token[],
      };
    },
    setTokenContent: (state, action: PayloadAction<Token>) => {
      return { ...state, token: action.payload };
    },
    setTokenContentList: (state, action: PayloadAction<Token[]>) => {
      return { ...state, tokenContentList: action.payload };
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      return { ...state, currentPage: action.payload };
    },
  },
});

export const { connectAccount, connectServer, disconnect, setTokenContent, setTokenContentList, setCurrentPage } =
  authSlice.actions; //* 액션함수

export default authSlice.reducer;
